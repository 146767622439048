<app-topnav class="position-relative mb-5"></app-topnav>
<div class="position-relative">
    <div class="mb-5 position-relative"></div>

    <div class="mt-5 col-12 position-absolute">

        <div class="modal-body py-3 d-flex justify-content-center">

            <div class="card shadow card-contrat card-contrat-sante mt-3 p-2 w-auto" style="min-width: 500px;">
                <div class="modal-header row mx-3">
                    <h2>Choix de l'employeur</h2>
                </div>
                <table class="table table-sm table-borderless table-striped fixed-header" #tableDelegations>
                    <thead>
                        <th>N° Employeur</th>
                        <th>Dénomination</th>
                        <!-- <th>Responsable délégation</th> -->
                        <th>Voir</th>
                    </thead>
                    <tbody>
                        <ng-container>
                            <tr *ngFor="let employer of employers">
                                <td>
                                    <span
                                        class="font-weight-medium font-size-150 text-dark">{{employer.Num_employeur}}</span>
                                </td>
                                <td>
                                    <span
                                        class="font-weight-medium font-size-150 text-dark">{{employer.Nom_employeur}}</span>
                                </td>
                                <!-- <td>
                                    <span
                                        class="font-weight-medium font-size-150 text-dark">{{employer.Responsable_delegation
                                       }}</span>
                                </td> -->
                                <td>
                                    <div class="row">
                                        <div class="col-2 mr-2">
                                            <button type="button" class="btn btn-outline-primary icon-button small"
                                                (click)="selectEmployer(employer.Num_employeur,employer.Nom_employeur,employer.Responsable_delegation)">
                                                <i class="simple-icon-magnifier"></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>